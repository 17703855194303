<template>
    <div>
        <div class="mt-1">
            <div class="row">
                <!-- <div class="col-md-12">
                    <div class="card text-white bg-info iq-mb-1 text-center mb-1 mt-1">
                        <div class="card-body pt-1 pb-1 ">
                            <b> Total Belanja Kegiatan =
                                Rp {{formatPrice( rumpun.id_rumpun.rumpun_komponen[0].nominal) }} + (Rp
                                {{formatPrice( rumpun.id_rumpun.rumpun_komponen[1].nominal) }} per
                                {{ rumpun.id_rumpun.rumpun_komponen[1].nm_nm_konstantan}} +
                                Rp {{formatPrice( rumpun.id_rumpun.rumpun_komponen[2].nominal) }} per
                                {{ rumpun.id_rumpun.rumpun_komponen[2].nm_nm_konstantan}} )</b>

                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="email"> Jumlah {{ rumpun.id_rumpun.rumpun_komponen[1].nm_konstantan}}</label>
                        <CurrencyInput v-model="jumlah_peserta" class="form-control"
                            :placeholder=" rumpun.id_rumpun.rumpun_komponen[1].nm_konstantan"
                            @input="Rumus(belanja_rka,jumlah_peserta, hari )" />
                        <small class="float-right"><i>Rp. {{vrs_jumlah_peserta}}, -</i></small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="text">Jumah {{ rumpun.id_rumpun.rumpun_komponen[2].nm_konstantan}}</label>
                        <CurrencyInput v-model="hari" class="form-control"
                            :placeholder=" rumpun.id_rumpun.rumpun_komponen[2].nm_konstantan"
                            @input="Rumus(belanja_rka,jumlah_peserta, hari )" />
                        <small class="float-right"><i>Rp. {{vrs_hari}}, -</i></small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="text">Total Belanja Sub Kegiatan Menurut RKA </label>
                        <CurrencyInput v-model="belanja_rka" class="form-control"
                            @input="Rumus(belanja_rka, jumlah_peserta, hari)" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group text-center">
                        <h4 class="text-center">Batas Atas Belanja Sub kegiatan Menurut ASB </h4>
                        <h3 id="rupiah"><b>Rp. {{batas_atas}}</b></h3>
                    </div>
                </div>

                <div class="col-lg-12" v-if="belanja_rka < 2">
                    <div class="card iq-mb-3 text-white bg-warning text-center  pt-1 pm-1">
                        <div class="card-body pt-1 pb-1">
                            <h4 class="card-title text-white">
                                <i class="fa fa-bell"></i> &nbsp;No Conclusions</h4>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" v-if="(kesimpulan =='Tidak Wajar'  && belanja_rka > 1)">
                    <div class="card iq-mb-3 text-white bg-danger text-center  pt-1 pm-1">
                        <div class="card-body pt-1 pb-1">
                            <h4 class="card-title text-white">
                                <i class="fa fa-warning"></i> &nbsp;Tidak Wajar</h4>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" v-else-if="(kesimpulan =='Wajar'  && belanja_rka > 1 )">
                    <div class="card iq-mb-3 text-white bg-info text-center  pt-1 pm-1">
                        <div class="card-body pt-1 pb-1">
                            <h4 class="card-title text-white">
                                <i class="fa fa-check"></i>&nbsp; Wajar</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" v-else>

                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-12">
                    <button v-if="jumlah_peserta > 0 && hari >0 && belanja_rka > 0"
                        class=" btn dark-icon btn-info btn-block mb-1" @click="simpan()"> <i
                            class="fa fa-save mr-1"></i>Simpan</button>


                    <button class=" btn dark-icon btn-warning btn-block mb-1 mt-1 " @click="batal()"> <i
                            class="fa fa-close mr-1"></i>Batal</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex';
    import CurrencyInput from "./CurrencyInput.vue";
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive';
    import {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    export default {
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            vSelect,
            CurrencyInput
        },
        data() {
            return {
                cons_n: 0,
                rp_jumlah_peserta: 0,
                rp_hari: 0,
                belanja_rka: 0,
                jumlah_peserta: 0,
                hari: 0,
                vrs_jumlah_peserta: 0,
                vrs_hari: 0,
                value_2: 0,
                kesimpulan: "",
                batas_atas: 0
            }
        },
        props: {
            input_kegiatan: {}
        },
        mixins: [Base],
        directives: {
            Ripple,
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.input.rs_dinas,
                dinas: state => state.input.dinas,
                rs_sub_kegiatan: state => state.input.rs_sub_kegiatan,
                sub_kegiatan: state => state.input.sub_kegiatan,
                count_data: state => state.input.count_data,
                rumpun: state => state.input.rumpun,
            }),

        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_rs_dinas: 'set_rs_dinas',
                set_dinas: 'dinas',
                set_rs_sub_kegiatan: "set_rs_sub_kegiatan",
                set_sub_kegiatan: "set_sub_kegiatan",
                set_count_data: 'set_count_data',
                set_rumpun: "set_rumpun"
            }),
            batal() {
                this.$emit("closebtn", ""),
                    this.$root.$emit('bv::hide::modal', 'modal-lg')
            },
            kesimpulan_asb(belanja_rka, value_2) {
                if (belanja_rka > value_2) {
                    this.kesimpulan = "Tidak Wajar";
                } else {
                    this.kesimpulan = "Wajar";
                }
            },
            Rumus(belanja_rka, jumlah_peserta, hari) {
                this.cons_n = parseInt(this.rumpun.id_rumpun.rumpun_komponen[0].nominal);
                this.rp_jumlah_peserta = parseInt(this.rumpun.id_rumpun.rumpun_komponen[1].nominal);
                this.rp_hari = parseInt(this.rumpun.id_rumpun.rumpun_komponen[2].nominal);


                const rs_peserta = jumlah_peserta * this.rp_jumlah_peserta;
                const rs_hari = hari * this.rp_hari;
                this.value_2 = this.cons_n + (rs_peserta + rs_hari);

                this.kesimpulan_asb(parseInt(belanja_rka), parseInt(this.value_2));
                this.vrs_jumlah_peserta = this.formatPrice(rs_peserta);
                this.vrs_hari = this.formatPrice(rs_hari);
                this.batas_atas = this.formatPrice(this.value_2);
            },
            formatPrice(value_2) {
                let val = (value_2 / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            async load_count_asb() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/count',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_count_data(response.data.result);
                        
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async simpan() {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/asb_kewajaran_belanja/input/sub_kegiatan',
                        data: {
                            rumus: "9",
                            id_kegiatan: self.input_kegiatan.id_kegiatan.id_kegiatan,
                            id_sub_kegiatan: self.input_kegiatan.id_sub_kegiatan.id_sub_kegiatan,
                            id_master_kode_dinas: self.input_kegiatan.id_master_kode_dinas.id_master_kode_dinas,
                            id_kode_dinas_transaksi: self.input_kegiatan.id_master_kode_dinas
                                .id_kode_dinas_transaksi,
                            id_rumpun: self.input_kegiatan.id_rumpun.id,
                            total_belanja: parseInt(self.belanja_rka),
                            batas_atas_belanja: parseInt(self.value_2),

                            id_rumpun_komponen1: self.rumpun.id_rumpun.rumpun_komponen[0].id,
                            id_rumpun_komponen2: self.rumpun.id_rumpun.rumpun_komponen[1].id,
                            id_rumpun_komponen3: self.rumpun.id_rumpun.rumpun_komponen[2].id,

                            cons_n: self.rumpun.id_rumpun.rumpun_komponen[0].nominal,
                            peserta: self.jumlah_peserta,
                            hari: self.hari,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('warning', "Info", response.data.message); 
                        self.load_count_asb();
                        self.batal(); 

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

        }
    }
</script>

<style>

</style>