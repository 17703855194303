<template>
    <div>
        <b-modal id="edit_Asb" size="lg" hide-footer no-close-on-backdrop title="Tambah Belanja Sub Kegiatan" no-footer>
            <div class="row">
                <!-- <div class="col-md-3" v-if="rs_kegiatan.st =='1'">
                    <button type="button"
                        class="btn btn-danger btn-sm btn-block waves-effect waves-float waves-light"><i
                            class="fa fa-trash mr-1"></i>Hapus Sub Kegiatan</button>
                </div> -->
                {{ sub_kegiatan }}
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <b-form-group label="Sub OPD" label-for="Sub OPD">
                         <b-form-input type="text" :placeholder="sub_kegiatan.kode_dinas.kode_dinas + '- ' + sub_kegiatan.kode_dinas.nm_dinas" disabled=""></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <b-form-group label="Nama Kegiatan" label-for="Sub OPD">
                        <v-select v-model="sub_kegiatan.kode_4_kegiatan"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="kegiatan"
                            :options="rs_kode_kegiatan" @input="kode_kegiatan_sub(sub_kegiatan.kode_4_kegiatan)" />
                    </b-form-group>
                </div>
                <div class="col-lg-12">
                    <b-form-group label="Sub Kegiatan" label-for="Sub OPD">
                        <v-select v-model="sub_kegiatan.kode_5_sub_kegiatan"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="sub_kegiatan"
                            :options="rs_kode_kegiatan_sub" @input="jenis_rumpun(sub_kegiatan.kode_5_sub_kegiata)" />
                    </b-form-group>

                </div>
            </div>
            <div > 
                <div class="row">
                    <div class="col-md-12 col-xl-12" v-if="sub_kegiatan.jenis_asb =='Fisik'"> 
                        <div :class="'card  bg_asb_fisik  text-white'">
                            <div class="card-body text-center">
                                <h3 class="text-white">ASB {{ sub_kegiatan.jenis_asb }} </h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-xl-12" v-if="sub_kegiatan.jenis_asb =='Non Fisik'"> 
                        <div :class="'card  bg_asb_non_fisik  text-white'">
                            <div class="card-body text-center">
                                <h3 class="text-white">ASB {{sub_kegiatan.jenis_asb}} </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <b-form-group :label="'Kategori Formula ASB '" label-for="Sub OPD">
                    <v-select v-model="sub_kegiatan.formula_jenis_rumpun" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="rumpun" :options="rs_jenis_rumpun" @input="setRumpun()" />
                </b-form-group>
            </div>
            <div >
                <Formula @closebtn=closebtn :input_kegiatan=sub_kegiatan></Formula>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive'
    import Formula from './formula/Index.vue';

    import {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            vSelect,
            Formula
        },
        data() {
            return {
                input_kegiatan: {},
                btn: "",
                rs_dinas_sub: [],
                rs_kode_kegiatan: [],
                rs_kode_kegiatan_sub: [],
                rs_jenis_rumpun: [],
                rs_kegiatan: {}
            }
        },
        emits: ["closebtn"],
        mixins: [Base],
        directives: {
            Ripple,
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.input.rs_dinas,
                dinas: state => state.input.dinas,
                rs_sub_kegiatan: state => state.input.rs_sub_kegiatan,
                sub_kegiatan: state => state.input.sub_kegiatan,
                count_data: state => state.input.count_data,
                rumpun: state => state.input.rumpun,
                input_sub_kegiatan: state => state.input.input_sub_kegiatan,
                st_asb: state => state.input.st_asb,
            }),
        },
        mounted() {
            this.dinas_sub();
            this.kode_kegiatan();
            this.input_kegiatan = {};

        },
        methods: {
            ...mapMutations({
                set_rs_dinas: 'set_rs_dinas',
                set_dinas: 'dinas',
                set_rs_sub_kegiatan: "set_rs_sub_kegiatan",
                set_sub_kegiatan: "set_sub_kegiatan",
                set_count_data: 'set_count_data',
                set_rumpun: "set_rumpun",
                set_input_sub_kegiatan: "set_input_sub_kegiatan",
                set_st_asb: 'set_st_asb'
            }),

            closebtn() {
                this.load_data();
                this.input_kegiatan = {};
                this.$emit("closebtn", "");
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/get',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            cari: "",
                            offset: "",
                            jenis_asb: self.st_asb.jenis_asb,
                            status_asb: self.st_asb.status_asb
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_sub_kegiatan(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async dinas_sub() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/get_dinas_sub',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_dinas_sub = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async kode_kegiatan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/kode_kegiatan',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_kode_kegiatan = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async kode_kegiatan_sub() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/kode_kegiatan_sub',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            id_kegiatan: self.sub_kegiatan.kode_4_kegiatan.id_kegiatan
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_kode_kegiatan_sub = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async jenis_rumpun() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/jenis_rumpun',
                        data: {
                            id_master_kode_dinas: self.sub_kegiatan.id_master_kode_dinas,
                            id_kegiatan: self.sub_kegiatan.kode_4_kegiatan.id_kegiatan,
                            id: self.sub_kegiatan.kode_5_sub_kegiatan.id_sub_kegiatan,
                            jenis_rumpun: self.sub_kegiatan.kode_5_sub_kegiatan.jenis_asb,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_jenis_rumpun = response.data.result;
                        self.act_insert_kegiatan();

                        if (self.input_kegiatan.id_sub_kegiatan.jenis_asb == 'Fisik') {
                            self.btn = 'bg_asb_fisik';
                        } else {
                            self.btn = 'bg_asb_non_fisik';
                        }

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            setRumpun() {
                this.set_rumpun(this.input_kegiatan)
            },

            async act_insert_kegiatan() {
                // const self = this;
                // await axios({
                //         method: 'POST',
                //         url: '/api/asb_kewajaran_belanja/tambah_kegiatan/act_insert_kegiatan',
                //         data: {
                //             id: self.rs_kegiatan.id,
                //             id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                //             id_kode_dinas_transaksi: self.input_kegiatan.id_master_kode_dinas
                //                 .id_kode_dinas_transaksi,
                //             id_kegiatan: self.input_kegiatan.id_kegiatan.id_kegiatan,
                //             id_sub_kegiatan: self.input_kegiatan.id_sub_kegiatan.id_sub_kegiatan,
                //             jenis_rumpun: self.input_kegiatan.id_sub_kegiatan.jenis_asb,
                //         },
                //         headers: {
                //             'Authorization': self.isAuthenticated
                //         }
                //     })
                //     .then(function (response) {
                //         self.rs_kegiatan = response.data.result;
                //         self.notification('warning', "Info", response.data.message);

                //     }).catch(err => {
                //         self.pesan = err.message;
                //         self.notification('warning', "Error", err.message);
                //     });
            },
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .bg_asb_fisik {
        background: #45b0a7;
    }

    .bg_asb_non_fisik {
        background: #2f8f28c7;
    }
</style>
