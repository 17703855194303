<template>
  <div>   
    {{ input_kegiatan.formula_jenis_rumpun }}
    <rumpun1  v-if="input_kegiatan.formula_jenis_rumpun.rumus =='1'" :input_kegiatan = input_kegiatan  @closebtn = closebtn ></rumpun1>
    <!-- <rumpun6  v-if="input_kegiatan.formula_jenis_rumpun.rumus =='6'" :input_kegiatan = input_kegiatan @closebtn = closebtn></rumpun6>
    <rumpun9  v-if="input_kegiatan.formula_jenis_rumpun.rumus =='9'" :input_kegiatan = input_kegiatan @closebtn = closebtn></rumpun9>
    <rumpun28 v-if="input_kegiatan.formula_jenis_rumpun.rumus =='28'" :input_kegiatan = input_kegiatan @closebtn = closebtn></rumpun28> -->
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState
  } from 'vuex'
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios';
  import Ripple from 'vue-ripple-directive'
  import rumpun1 from './rumpun1.vue'
  import rumpun6 from './rumpun6.vue'
  import rumpun9 from './rumpun9.vue'
  import rumpun28 from './rumpun28.vue'
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      vSelect,
      rumpun1,
      rumpun6,
      rumpun9,
      rumpun28

    },
    data() {
      return {

      }
    },
    props:{
      input_kegiatan : {}
    },
    mixins: [Base],
    directives: {
      Ripple,
    },
    emits: ["closebtn"],
    computed: {
      ...mapState({
        rs_dinas: state => state.input.rs_dinas,
        dinas: state => state.input.dinas,
        rs_sub_kegiatan: state => state.input.rs_sub_kegiatan,
        sub_kegiatan: state => state.input.sub_kegiatan,
        count_data: state => state.input.count_data,
        rumpun: state => state.input.rumpun,
      }),

    },
    mounted() {},
    methods: {
      ...mapMutations({
        set_rs_dinas: 'set_rs_dinas',
        set_dinas: 'dinas',
        set_rs_sub_kegiatan: "set_rs_sub_kegiatan",
        set_sub_kegiatan: "set_sub_kegiatan",
        set_count_data: 'set_count_data',
        set_rumpun: "set_rumpun"
      }),

      closebtn(){
        this.$emit("closebtn", "");
      }
    }
  }
</script>

<style>

</style>