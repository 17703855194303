<template>
    <div> 
        <b-modal id="detail_asb" size="lg" hide-footer no-close-on-backdrop title="Tambah Belanja Sub Kegiatan" no-footer>
            <div class="row">   
            </div>
            <div class="row">
                <div class="col-md-5"><b>{{ sub_kegiatan.kode_dinas.kode_dinas }}</b></div>
                <div class="col-md-7"><b>{{ sub_kegiatan.kode_dinas.nm_dinas }}</b></div>
            </div>
            <div class="row" v-if="sub_kegiatan.kode_sub_dinas != null">
                <div class="col-md-5"><b>{{ sub_kegiatan.kode_sub_dinas.kode_dinas }}</b></div>
                <div class="col-md-7"><b>{{ sub_kegiatan.kode_sub_dinas.nm_dinas }}</b></div>
            </div>

            <div class="row">
                <div class="col-md-5"> {{ sub_kegiatan.kode_dinas.kode_dinas }}  {{ sub_kegiatan.sub_kode_bagian.kode_bidang }} </div>
                <div class="col-md-7"> {{ sub_kegiatan.sub_kode_bagian.nama_bidang }} </div>
            </div>
            <div class="row">
                <div class="col-md-5"> {{ sub_kegiatan.kode_dinas.kode_dinas }}  {{ sub_kegiatan.sub_kode_bagian.kode_program }} </div>
                <div class="col-md-7"> {{ sub_kegiatan.sub_kode_bagian.nama_program }} </div>
            </div>
            <div class="row">
                <div class="col-md-5"> {{ sub_kegiatan.kode_dinas.kode_dinas }}  {{ sub_kegiatan.sub_kode_bagian.kode_kegiatan }} </div>
                <div class="col-md-7"> {{ sub_kegiatan.sub_kode_bagian.nama_kegiatan }} </div>
            </div>
            <div class="row">
                <div class="col-md-5"> {{ sub_kegiatan.kode_dinas.kode_dinas }}  {{ sub_kegiatan.kode_sub_kegiatan }} </div>
                <div class="col-md-7"> {{ sub_kegiatan.nama_sub_kegiatan }} </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-5">Kinerja </div>
                <div class="col-md-7"> {{  sub_kegiatan.kinerja}}</div>
            </div>
            <div class="row">
                <div class="col-md-5">Indikator </div>
                <div class="col-md-7"> {{  sub_kegiatan.indikator}}</div>
            </div>
            <div class="row">
                <div class="col-md-5">Satuan </div>
                <div class="col-md-7"> {{  sub_kegiatan.satuan}}</div>
            </div>
            <hr> 
            <div class="row">
                <div class="col-md-12 text-center mt-1"><h4> Kategori Rumpun {{ sub_kegiatan.nm_rumpun.jenis_rumpun }}<br>   {{ sub_kegiatan.nm_rumpun.kode_rumpun }} {{ sub_kegiatan.nm_rumpun.nama_rumpun }} </h4>  </div> 
            </div>

            <!-- <div class="row mt-1 text-white">
                <div class="col-lg-12" >
                    <div class="card text-white bg-info text-center ">
                        <div class="card-body pt-1 pb-1">
                        <h5 class=" text-white">
                              {{  sub_kegiatan.rumus_asb}} 
                        </h5> 
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="row">
                <div class="col-md-12 text-center  ">
                     <b>Batas Atas Belanja Sub kegiatan Menurut ASB</b>
                     <h3><b>Rp. {{ formatRp(sub_kegiatan.batas_atas_belanja) }},- </b></h3>
                     <b>Total Belanja RKA</b>
                     <h3><b>Rp. {{ formatRp(sub_kegiatan.total_belanja) }},- </b></h3>
                    </div> 
            </div>

            <div class="row mt-1 text-white" v-if="sub_kegiatan.status_asb =='Wajar'">
                <div class="col-lg-12" >
                    <div class="card iq-mb-3 text-white bg-info text-center ">
                        <div class="card-body pt-1 pb-1">
                            <feather-icon icon="CheckCircleIcon" size="40" class="" />
                            <h3 class="text-white"><b>{{ sub_kegiatan.status_asb }}</b></h3> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1 text-white" v-if="sub_kegiatan.status_asb !='Wajar'">
                <div class="col-lg-12" >
                    <div class="card iq-mb-3 text-white bg-danger text-center ">
                        <div class="card-body pt-1 pb-1">
                            <feather-icon icon="AlertCircleIcon" size="40" class="" />
                            <h3  class="text-white"><b>{{ sub_kegiatan.status_asb }}</b></h3> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">

                </div>
            </div> 
        </b-modal>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive' 

    import {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
import { format } from 'path';

    export default {
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            vSelect 
        },
        data() {
            return {
                input_kegiatan: {},
                btn: "",
                rs_dinas_sub: [],
                rs_kode_kegiatan: [],
                rs_kode_kegiatan_sub: [],
                rs_jenis_rumpun: [],
                rs_kegiatan: {}
            }
        },
        emits: ["closebtn"],
        mixins: [Base],
        directives: {
            Ripple,
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.input.rs_dinas,
                dinas: state => state.input.dinas,
                rs_sub_kegiatan: state => state.input.rs_sub_kegiatan,
                sub_kegiatan: state => state.input.sub_kegiatan,
                count_data: state => state.input.count_data,
                rumpun: state => state.input.rumpun,
                input_sub_kegiatan: state => state.input.input_sub_kegiatan,
                st_asb: state => state.input.st_asb,
            }),
        },
        mounted() {
            this.dinas_sub();
            this.kode_kegiatan();
            this.input_kegiatan = {};
            this.set_st_asb("Fisik","Wajar");

        },
        methods: {
            ...mapMutations({
                set_rs_dinas: 'set_rs_dinas',
                set_dinas: 'dinas',
                set_rs_sub_kegiatan: "set_rs_sub_kegiatan",
                set_sub_kegiatan: "set_sub_kegiatan",
                set_count_data: 'set_count_data',
                set_rumpun: "set_rumpun",
                set_input_sub_kegiatan: "set_input_sub_kegiatan",
                set_st_asb: 'set_st_asb'
            }),
            closebtn() {
                this.load_data();
                this.input_kegiatan = {};
                this.$emit("closebtn", "");
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/get',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            cari: "",
                            offset: "",
                            jenis_asb: self.st_asb.jenis_asb,
                            status_asb: self.st_asb.status_asb
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_sub_kegiatan(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async dinas_sub() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/get_dinas_sub',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_dinas_sub = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async kode_kegiatan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/kode_kegiatan',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_kode_kegiatan = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async kode_kegiatan_sub() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/kode_kegiatan_sub',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            id_kegiatan: self.input_kegiatan.id_kegiatan.id_kegiatan
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_kode_kegiatan_sub = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async jenis_rumpun() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/tambah_kegiatan/jenis_rumpun',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            id_kegiatan: self.input_kegiatan.id_kegiatan.id_kegiatan,
                            id: self.input_kegiatan.id_sub_kegiatan.id_sub_kegiatan,
                            jenis_rumpun: self.input_kegiatan.id_sub_kegiatan.jenis_asb,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs_jenis_rumpun = response.data.result;
                        self.act_insert_kegiatan();

                        if (self.input_kegiatan.id_sub_kegiatan.jenis_asb == 'Fisik') {
                            self.btn = 'bg_asb_fisik';
                        } else {
                            self.btn = 'bg_asb_non_fisik';
                        }

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            setRumpun() {
                this.set_rumpun(this.input_kegiatan)
            },

            async act_insert_kegiatan() {
             
            },
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .bg_asb_fisik {
        background: #45b0a7;
    }

    .bg_asb_non_fisik {
        background: #2f8f28c7;
    }
</style>
